<template>
  <div class="register animated bounceInRight">
    <back style="color:#000" />
    <h2 class="welcome">注册</h2>
    <div class="usermsg">
      <div class="msgList">
        <span class="iconfont icon-dianhua-"></span>
        <van-field v-model="phone" type="tel" placeholder="请输入手机号" clearable />
      </div>

      <div class="msgList">
        <span class="icon-baohu iconfont"></span>
        <van-field v-model="code" clearable placeholder="请输入验证码" />
        <span class="red" @click="getCode" v-if="codeFlag">获取验证码</span>
        <span class="red" style="min-width: 130px;" v-if="!codeFlag">({{currentS}})秒后重新获取</span>
      </div>

      <div class="msgList">
        <span class="icon-mima iconfont"></span>
        <van-field v-model="pass" clearable type="password" placeholder="6-16位密码(数字和字母的组合)" />
      </div>
    </div>

    <div class="edit_btn">
      <van-button
        type="danger"
        round
        class="register_btn"
        size="small"
        :disabled="regiAble"
        @click="register"
      >注册账号</van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import authApi from '@/api/auth'
export default {
  data() {
    return {
      phone: "",
      code: "",
      pass: "",
      codeFlag: true,
      currentS: 60,
      appName: this.appName,
      regiAble: false
    };
  },
  mounted() {
    this.currentS = 60;
  },
  methods: {
    sendCode() { // 发短信接口
      this.codeFlag = !this.codeFlag;
      this.currentS = 60;
      var timeId = setInterval(() => {
        this.currentS--;
        if (this.currentS == 0) {
          this.codeFlag = !this.codeFlag;
          clearInterval(timeId)
          return;
        }
      }, 1000);

      var params = {
        appName: this.appName,
        phone: this.phone
      }
      authApi.obtainCode(params).then(res => {
        if (res.code === 3007) {
          Toast("获取验证码成功")
        } else {
          Toast(res.message)
        }
      }).catch(err => {
        Toast("请检查网络是否可用!")
      });

    },
    //获取验证码
    getCode() {
      if (!this.phone) {
        Toast("请输入手机号");
        return;
      }
      if (this.phone.length != 11) {
        Toast("手机号输入有误");
        return;
      }
      var data = {
        appName: this.appName,
        phone: this.phone
      }
      // 判断用户手机号知否注册
      authApi.checkRegisterPhone(data).then(res => {
        if (res.code === 10000) {
          if (res.data == true) {
            Toast("该手机号已经注册过了");
            return
          } else {
            this.sendCode();
          }
        }
      })
    },
    registerSubmit() {
      if (this.pass.length > 16 || this.pass.length < 6) {
        Toast("请输入6-16位密码(数字和字母的组合)");
        return
      }
      const data = {
        appName: this.appName,
        code: this.code,
        password: this.pass,
        phone: this.phone
      };
      this.regiAble = true;
      authApi.memberRegister(data).then(res => {
        this.regiAble = false;
        if (res.code === 10000) {
          Toast("注册成功");
          setTimeout(() => {
            this.$router.go(-1);
          }, 1000);
        } else {
          Toast(res.message);
        }
      }).catch(err => {
        this.regiAble = false;
        console.log(err);
        Toast("注册失败!");
      });

      // this.$http
      //   .post(this.javalinkTwo + `app/ap_member//member/add/register_account`, data)
      //   .then(res => {
      //     // console.log(res);
      //     if (res.data.code === 10000) {
      //       Toast("注册成功");
      //       // localStorage.userMsg = JSON.stringify({
      //       //   username:this.phone,
      //       //   password:this.pass
      //       // })
      //       setTimeout(() => {
      //         this.$router.go(-1);
      //       }, 1000);
      //     } else {
      //       Toast(res.data.message);
      //     }
      //   })
      //   .catch(err => {
      //     console.log(err);
      //     Toast("注册失败，请联系管理员");
      //   });
    },
    register() { // 填写验证码
      if (!this.phone || this.phone.length != 11) {
        Toast("请正确输入手机号")
        return
      }
      if (!this.code) {
        Toast("请输入验证码")
        return
      }
      if (!this.pass) {
        Toast("请输入密码")
      }

      // 验证码验证
      var params = {
        code: this.code,
        phone: this.phone
      }

      authApi.verificationCodeIsTrue(params).then(res => {
        if (res.code === 10000) {
          // 验证成功
          this.registerSubmit();
        } else {
          Toast("验证码错误")
        }
      })
    }
  }
};
</script>

<style lang="less" scoped>
.register {
  padding: 0 20px;
  box-sizing: border-box;
  .back {
    font-size: 20px;
    position: absolute;
    left: 10px;
    top: 40px;
  }
  .red {
    color: #e42417;
    float: right;
    min-width: 100px;
    text-align: center;
    font-size: 14px;
  }
  .edit_btn {
    margin: 30px auto 0;
    display: flex;
    justify-content: center;
    .register_btn {
      padding: 0 30px;
      font-size: 16px;
    }
  }
  .usermsg {
    margin-top: 45px;
    padding: 0 15px;
    box-sizing: border-box;
    .msgList {
      display: flex;
      justify-content: left;
      align-items: center;
      border-bottom: 1px solid #eee;
    }
  }
  .welcome {
    font-size: 20px;
    font-weight: 700;
    margin-top: 80px;
  }
}
</style>